<template>
  <div class="w-full min-h-48 bg-bg-secondary">
    <div
      class="auto-container flex justify-between items-center pt-6 flex-col md:flex-row"
    >
      <nav class="flex flex-wrap gap-x-8 gap-y-4">
        <NuxtLink v-for="item in navItems" :to="$l(item.url)">
          <span class="hover:text-primary">{{
            $t(`static.${item.label}.title`)
          }}</span>
        </NuxtLink>
      </nav>
      <div class="flex flex-none justify-between mt-5">
        <div class="flex flex-col items-center">
          <h3 class="text-sm text-start leading-9">
            {{ $t('footer.follow_us') }}
          </h3>
          <div class="flex flex-row gap-2 text-white">
            <NuxtLink
              href="https://www.instagram.com/Tazaexpressiq/"
              target="_blank"
            >
              <button class="btn-primary btn-hover">
                <i class="icon-fab:instagram flex flex-none text-lg"></i>
              </button>
            </NuxtLink>
            <NuxtLink
              to="https://www.facebook.com/tazaexpressiq/"
              target="_blank"
            >
              <button class="btn-primary btn-hover">
                <i class="icon-fab:facebook flex flex-none text-lg"></i>
              </button>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-10 flex-center-none">
      <span class="leading-9 text-gray">&copy; 2021 Taza.iq</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const navItems = [
  { label: 'careers', url: '/careers' },
  { label: 'terms', url: '/terms' },
  { label: 'privacy', url: '/privacy' },
  { label: 'returnPolicy', url: '/return-policy' },
  { label: 'contact', url: '/contact' },
];
</script>

<style scoped lang="scss"></style>
