<template>
  <div class="default-layout min-h-100vh">
    <SidebarMenu></SidebarMenu>

    <div class="page-content pt-16 min-h-inherit flex flex-col">
      <PageHeader />
      <section
        class="flex flex-col items-center gap-y-4 bg-bg-primary relative"
      >
        <slot></slot>
      </section>
      <div class="min-h-4 w-full flex-1"></div>
      <PageFooter />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

const { sidebarOpen } = storeToRefs(useMainStore());
const head = useLocaleHead({
  addDirAttribute: true,
});

useHead({
  htmlAttrs: {
    dir: computed(() => head.value.htmlAttrs?.dir ?? 'ltr'),
    // class: computed(() => (sidebarOpen.value ? 'disable-scroll' : '')),
  },
});
</script>

<style scoped lang="scss"></style>
