<template>
  <button class="btn-flat-primary h-12 flex-center-none" @click="toggle">
    <div
      class="duration-500 transform !h-8 !w-8"
      :class="[
        sidebarOpen ? 'icon-mdi:menu-open ' : 'icon-mdi:menu ',
        locale !== 'ar' ? 'rotate-y-180' : 'rotate-0',
      ]"
    ></div>
  </button>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

const $main = useMainStore();
const { sidebarOpen } = storeToRefs($main);
const { locale } = useI18n();
const toggle = () => $main.toggleSidebar();
</script>

<style scoped></style>
