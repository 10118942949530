<template>
  <div class="page-header">
    <div class="auto-container h-full flex items-center gap-x-4 px-2 sm:px-0">
      <NuxtLink :to="$l('/')" class="h-full py-1">
        <img
          v-if="locale == 'ar'"
          src="/logo/logo-ar.png"
          class="h-full object-contain"
        />
        <img v-else src="/logo/logo-en.png" class="h-full object-contain" />
      </NuxtLink>
      <nav
        class="flex-1 flex items-center gap-x-4 sm:ps-8 text-sm sm:text-base invisible sm:visible capitalize"
      >
        <NuxtLink
          class="hover:text-primary"
          :to="{
            path: $l('/contact'),
          }"
        >
          {{ $t('hero.become_partner') }}
        </NuxtLink>
        <NuxtLink
          class="hover:text-primary"
          :to="{
            path: $l('/'),

            hash: '#get-app',
          }"
        >
          {{ $t('hero.get_app') }}
        </NuxtLink>
      </nav>
      <LocaleSwitch></LocaleSwitch>
      <HamburgerMenu></HamburgerMenu>
    </div>
  </div>
</template>

<script setup lang="ts">
const { locale } = useI18n();
</script>

<style scoped lang="scss">
.page-header {
  --at-apply: 'bg-white w-full h-16 shadow fixed top-0 inset-y-0 z-99';
}
</style>
