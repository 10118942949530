<template>
  <div class="flex-center-none">
    <button
      @click="changeLocale"
      class="btn-outlined-primary rounded-full h-8 items-center flex"
    >
      {{ $t('localeChange') }}
    </button>
  </div>
</template>

<script setup lang="ts">
const { locale, setLocale, localeCodes } = useI18n();

function changeLocale() {
  const i = localeCodes.value.findIndex((l) => l === locale.value);
  const nextLocale = localeCodes.value.at(i + 1) || localeCodes.value.at(0);

  if (nextLocale) setLocale(nextLocale);
}
</script>

<style scoped></style>
