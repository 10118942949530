<template>
  <Sidebar v-model:visible="sidebarOpen">
    <div class="flex flex-col flex-1 h-full">
      <LocaleSwitch class="self-end"></LocaleSwitch>

      <NuxtLink :to="$l('/')" class="h-24 py-2">
        <img v-if="locale == 'ar'" src="/logo/logo-ar.png" class="w-full" />
        <img v-else src="/logo/logo-en.png" class="w-full" />
      </NuxtLink>
      <Divider></Divider>

      <div class="flex flex-col flex-1 gap-2">
        <NuxtLink
          class="hover:text-primary"
          :to="{
            path: $l('/contact'),
          }"
        >
          {{ $t('hero.become_partner') }}
        </NuxtLink>
        <NuxtLink
          class="hover:text-primary"
          :to="{
            path: $l('/'),

            hash: '#get-app',
          }"
        >
          {{ $t('hero.get_app') }}
        </NuxtLink>
      </div>
      <div class="flex-center-1"></div>
      <Divider></Divider>

      <div class="w-full flex justify-between">
        <nav class="flex flex-col gap-y-2">
          <NuxtLink v-for="item in navItems" :to="$l(item.url)">
            <span class="hover:text-primary">{{
              $t(`static.${item.label}.title`)
            }}</span>
          </NuxtLink>
        </nav>
        <div class="flex flex-none justify-between mt-5">
          <div class="flex flex-col items-center">
            <h3 class="text-sm text-start leading-9">
              {{ $t('footer.follow_us') }}
            </h3>
            <div class="flex flex-row gap-2 text-white">
              <NuxtLink
                href="https://www.instagram.com/Tazaexpressiq/"
                target="_blank"
              >
                <button class="btn-primary btn-hover">
                  <i class="icon-fab:instagram flex flex-none text-lg"></i>
                </button>
              </NuxtLink>
              <NuxtLink
                to="https://www.facebook.com/tazaexpressiq/"
                target="_blank"
              >
                <button class="btn-primary btn-hover">
                  <i class="icon-fab:facebook flex flex-none text-lg"></i>
                </button>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
const { locale } = useI18n();

const $main = useMainStore();
const { sidebarOpen } = storeToRefs($main);

const navItems = [
  { label: 'careers', url: '/careers' },
  { label: 'terms', url: '/terms' },
  { label: 'privacy', url: '/privacy' },
  { label: 'returnPolicy', url: '/return-policy' },
  { label: 'contact', url: '/contact' },
];
</script>

<style scoped></style>
